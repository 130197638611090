import React, { useState } from "react";

function useTextarea({ type, placeholder, defaultValue, rows }) {
    const [value, setValue] = useState(defaultValue);
    const input = (
        <textarea
            value={value}
            rows={rows}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}
            type={type}
        />
    );
    return [value, input];
}

export default useTextarea;
