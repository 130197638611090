import React from "react";
import { Column, Table } from "react-virtualized";
import AutoSizer from "react-virtualized-auto-sizer";
import { Control, SelectBox } from "../../sdk/Form";
import FullScreen from "../../components/Gallery2/FullScreen";
import { Button } from "../../sdk/Button";
import IconsLibrary from "../../components/Icons2";
import { getDetailValue } from "../../components/Product2/DetailProductTable";
import { numberWithCommas } from "../../utils";
import { ItemsTableView2 } from "../styles";
import { getOptions } from "../../utils";
import renderIf from "../../utils/renderIf";
import { getFair } from "../../components/Product2";

import "react-virtualized/styles.css";

const isBottom = (el) => {
	return (
		window.innerHeight + window.scrollY >= document.body.offsetHeight - 1500
	);
};

const imageCellRender = ({ rowData }) => {
	return (
		<figure
			tabIndex="0"
			onClick={() => rowData.handleImageGalleryView(rowData.imagesUrl)}>
			<img
				src={
					rowData.imagesUrl && rowData.imagesUrl.length > 0
						? rowData.imagesUrl[0].thumb
						: "https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg"
				}
				alt={rowData.sku}
			/>
		</figure>
	);
};
const deliveryDateRenderer = ({ rowData }) => {
	return <span>{rowData.deliveryDate}</span>;
};

const removeCellRender = ({ rowData }) => {
	const handleRemoveItem = () => {
		rowData.handleRemoveItem(
			rowData.productId,
			rowData.slug,
			rowData.cookie_token
		);
	};
	return (
		<Button
			onClick={handleRemoveItem}
			loading={rowData.isRemovingProducts}
			small
			title="Eliminar">
			<IconsLibrary icon="trash"></IconsLibrary>
		</Button>
	);
};
const quantityCellRender = ({ rowData }) => {
	const options = getOptions(
		rowData.multiple,
		rowData.available,
		rowData.check_availability,
		rowData.min_venta
	);

	const handleAddToCart = (e) => {
		rowData.addToCart(
			rowData.productId,
			parseInt(e.target.value),
			{
				sku: rowData.sku,
				slug: rowData.slug,
				title: rowData.title,
			},
			rowData.cookie_token
		);
	};
	return (
		<SelectBox>
			<select
				onChange={handleAddToCart}
				value={rowData.quantity}
				defaultValue={rowData.quantity}>
				{options.map((index) => (
					<option
						value={index.value}
						key={`option-${rowData._id}-${index.value}`}>
						{index.value}
					</option>
				))}
			</select>
		</SelectBox>
	);
};

const commentsCellRender = ({ rowData }) => {
	const handleOnComments = (e) => {
		rowData.handleSetComments(e.target.value, rowData.productId);
	};

	return (
		<Control key={`comments_${rowData.productId}`}>
			<textarea
				rows="2"
				defaultValue={rowData.comments}
				onChange={handleOnComments}></textarea>
		</Control>
	);
};

const getRow = (
	item,
	product,
	handleSetComments,
	handleRemoveItem,
	addToCart,
	handleImageGalleryView,
	cookie_token
) => {
	if (item == null) return {};
	return {
		bultos: product ? (item.quantity / product.multiple).toFixed(0) : 0,
		multiple: product ? product.multiple : 0,
		available: product ? product.available : 0,
		min_venta: product ? product.min_venta : 0,
		price_for_user_store: product ? product.price_for_user_store : 0,
		check_availability: product ? product.check_availability : true,
		sku: product ? product.sku : "",
		title: item.title,
		isFair: getFair(product),
		productId: item.productId,
		unit: getDetailValue(product, "unit"),
		price: item.price,
		deliveryDate: product ? product.deliveryDate : "",
		cookie_token,
		lineTotal: numberWithCommas(item.price * item.quantity),
		material: product ? getDetailValue(product, "material") : "",
		collection: product ? getDetailValue(product, "collection") : "",
		dimensions: product ? getDetailValue(product, "dimensions") : "",
		imagesUrl: item.imagesUrl,

		handleSetComments,
		handleRemoveItem,
		addToCart,
		handleImageGalleryView,
		...item,
	};
};

function TableView({
	itemsCarts,
	_t,
	handleSetComments,
	removeItem,
	addToCart,
	strings,
	isTableActive,
	itemsProducts,
	currentCarts,
	user,
}) {
	const [limit, setLimit] = React.useState(50);
	const [imagesGallery, setImagesGallery] = React.useState(null);
	const [currentImage, setCurrentImage] = React.useState(null);

	const timeoutRefs = React.useRef();
	const itemsToRender = [];

	const [isModalVisible, setIsModalVisitble] = React.useState(false);
	const toggleModal = () => {
		setIsModalVisitble((prevState) => !prevState);
	};

	const handleImageGalleryView = (images) => {
		setImagesGallery(images);
		setCurrentImage(images && images.length > 0 ? images[0].url : null);
		toggleModal(true);
	};

	const handleOnChangeComments = (comments, productId) => {
		if (
			timeoutRefs.current != null &&
			timeoutRefs.current.some((index) => index.productId === productId)
		) {
			const timeout = timeoutRefs.current.filter(
				(index) => index.productId === productId
			)[0];
			timeoutRefs.current = timeoutRefs.current.filter(
				(index) => index.productId !== productId
			);
			clearTimeout(timeout.timeout);
		}
		if (timeoutRefs.current == null) timeoutRefs.current = [];
		timeoutRefs.current.push({
			productId,
			timeout: setTimeout(() => {
				handleSetComments(comments, productId);
			}, 1000),
		});
	};

	const itemMap = React.useMemo(() => {
		const map = {};

		if (itemsProducts == null) return null;
		for (let index = 0; index < itemsProducts.length; index++) {
			const element = itemsProducts[index];
			map[element._id] = element;
		}

		return map;
	}, [itemsProducts]);

	const itemsFinal = React.useMemo(() => {
		const map = [];

		if (itemMap == null) return [];
		for (let j = 0; j < itemsCarts.length; j++) {
			const cart = itemsCarts[j];
			if (currentCarts.indexOf(cart._id) > -1) {
				for (let index = 0; index < cart.items.length; index++) {
					const element = cart.items[index];

					map.push(
						getRow(
							element,
							itemMap[element.productId],
							handleOnChangeComments,
							removeItem,
							addToCart,
							handleImageGalleryView,
							cart.cookie_token
						)
					);
				}
			}
		}

		return map;
	}, [itemMap, itemsCarts, currentCarts]);

	if (!isTableActive || itemsFinal == null || itemsFinal.length === 0)
		return null;

	return (
		<>
			{imagesGallery ? (
				<FullScreen
					images={imagesGallery}
					toggleModal={toggleModal}
					modal={isModalVisible}
					currentImage={currentImage}
					set={(img) => setCurrentImage(img)}
					alt={""}
				/>
			) : null}
			<ItemsTableView2>
				<AutoSizer>
					{({ width, height }) => {
						const realWidth = 1104;
						const finalWidth =
							width < realWidth ? realWidth : width;
						const multiplier = finalWidth / realWidth;
						return (
							<Table
								width={finalWidth}
								height={height}
								headerHeight={20}
								rowHeight={61}
								rowCount={itemsFinal.length}
								rowGetter={({ index }) => itemsFinal[index]}>
								<Column
									label={_t("boxes")}
									dataKey="bultos"
									width={40 * multiplier}
								/>
								<Column
									width={50 * multiplier}
									label="C.B.M"
									dataKey="cubicaje"
								/>
								<Column
									width={70 * multiplier}
									label={_t("sku")}
									dataKey="sku"
								/>
								<Column
									width={123 * multiplier}
									label={_t("description")}
									dataKey="title"
								/>
								<Column
									width={70 * multiplier}
									label={_t("quantity")}
									dataKey="quantity"
									cellRenderer={quantityCellRender}
								/>
								<Column
									width={50 * multiplier}
									label="UM"
									dataKey="unit"
								/>
								<Column
									width={50 * multiplier}
									label="Unit.Price"
									dataKey="price"
								/>
								<Column
									width={50 * multiplier}
									label="Importe USD"
									dataKey="lineTotal"
								/>
								{renderIf(
									user != null && user.storePrice != null
								)(
									<Column
										width={100 * multiplier}
										label={_t("priceStore")}
										dataKey="price_for_user_store"
									/>
								)}
								<Column
									width={100 * multiplier}
									label={_t("material")}
									dataKey="material"
								/>
								<Column
									width={100 * multiplier}
									label={_t("collection")}
									dataKey="collection"
								/>
								<Column
									width={100 * multiplier}
									label={_t("dimensions")}
									dataKey="dimensions"
								/>
								<Column
									width={70 * multiplier}
									label={_t("deliveryDate")}
									dataKey="deliveryDate"
									cellRenderer={deliveryDateRenderer}
								/>
								<Column
									width={50 * multiplier}
									label={_t("photo")}
									dataKey="img"
									cellRenderer={imageCellRender}
								/>
								<Column
									width={150 * multiplier}
									label={_t("comments")}
									dataKey="comments"
									cellRenderer={commentsCellRender}
								/>
								<Column
									width={50 * multiplier}
									label=""
									dataKey="productId"
									cellRenderer={removeCellRender}
								/>
							</Table>
						);
					}}
				</AutoSizer>
			</ItemsTableView2>
		</>
	);
}

export default TableView;
