import React from "react";

import { Button, Buttons } from "../../sdk/Button";
import { Message } from "../../sdk/Elements";
import { Field, Control } from "../../sdk/Form";
import IconsLibrary from "../../components/Icons2";
import { numberWithCommas } from "../../utils";
import useTextarea from "../../hooks/useTextarea";
import RelatedProducts from "./RelatedProducts";
import {
    Item,
    ItemWrapper,
    ItemInfo,
    Items,
    ItemImg,
    Comment,
    ItemActions,
} from "../styles";

export default function CartItem({
    item,
    strings,
    handleSetComments,
    removeItem,
    onItemEdit,
    isRemoved,
    cookie_token,
}) {
    const [isAddingComments, setIsAddingComments] = React.useState(false);
    const [isRemovingProducts, setIsRemovingProducts] = React.useState(false);
    const [isOppeningModal, setIsOppeningModal] = React.useState(false);

    const [comments, input] = useTextarea({
        type: "",
        rows: 2,
        defaultValue: item.comments,
    });

    const handleCommentsToggle = () => {
        setIsAddingComments((prev) => !prev);
    };

    const handleItemEdit = () => {
        setIsOppeningModal(true);
        onItemEdit(item.slug, false, item.quantity, cookie_token)
            .then(() => {
                setIsOppeningModal(false);
            })
            .catch(() => {
                setIsOppeningModal(false);
            });
    };

    const handleOnComments = () => {
        handleSetComments(comments, item.productId);
        setIsAddingComments(false);
    };

    const handleRemoveItem = () => {
        setIsRemovingProducts(true);
        removeItem(item.productId, item.slug, cookie_token)
            .then(() => {
                setIsRemovingProducts(false);
            })
            .catch(() => {
                setIsRemovingProducts(false);
            });
    };

    return (
        <Item>
            <ItemWrapper>
                <div className="image">
                    <ItemImg>
                        <span>{item.slug}</span>
                        <figure
                            onClick={handleItemEdit}
                            tabIndex="0"
                            role="button"
                        >
                            <img
                                src={
                                    item.imagesUrl && item.imagesUrl.length > 0
                                        ? item.imagesUrl[0].thumb
                                        : "https://www.noritex.com/uplds/2022/0/12/hoBAkXMjq5b8nsnMcno-image.jpg"
                                }
                                alt={item.slug}
                            />
                        </figure>
                        <a onClick={handleItemEdit}>{strings.related}</a>
                    </ItemImg>
                </div>
                <div className="info">
                    <ItemInfo>
                        <h3>{item.title}</h3>
                        {item.restriction ? (
                            <Message alert>{item.restriction}</Message>
                        ) : null}
                        {isAddingComments ? (
                            <Comment>
                                <Field>
                                    <label>{strings.comments}</label>
                                    <Control isExpanded>{input}</Control>
                                </Field>
                                <Field>
                                    <Buttons>
                                        <Button
                                            onClick={handleOnComments}
                                            small
                                        >
                                            {strings.save}
                                        </Button>
                                        <Button
                                            small
                                            onClick={handleCommentsToggle}
                                        >
                                            {strings.cancel}
                                        </Button>
                                    </Buttons>
                                </Field>
                            </Comment>
                        ) : isRemoved ? null : (
                            <>
                                {item.comments && item.comments !== "" ? (
                                    <p>{item.comments}</p>
                                ) : null}
                                <a onClick={handleCommentsToggle}>
                                    {item.comments && item.comments !== ""
                                        ? strings.editComments
                                        : strings.addComments}
                                </a>
                            </>
                        )}
                    </ItemInfo>
                    <div className="meta">
                        <table>
                            <tbody>
                                <tr>
                                    <th>{strings.price}</th>
                                    <td>: ${item.price}</td>
                                </tr>
                                <tr>
                                    <th>{strings.quantity}</th>
                                    <td>: {item.quantity}</td>
                                </tr>
                                <tr>
                                    <th>{strings.total}</th>
                                    <td>
                                        :{" "}
                                        {numberWithCommas(
                                            item.quantity * item.price
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {isRemoved ? null : (
                        <div className="actions">
                            <ItemActions>
                                <Button
                                    onClick={handleRemoveItem}
                                    loading={
                                        isRemovingProducts
                                            ? isRemovingProducts
                                            : undefined
                                    }
                                    small
                                    title="Eliminar"
                                >
                                    <IconsLibrary icon="trash"></IconsLibrary>
                                </Button>
                                <Button
                                    onClick={handleItemEdit}
                                    loading={
                                        isOppeningModal
                                            ? isOppeningModal
                                            : undefined
                                    }
                                    small
                                    title="Editar"
                                >
                                    <IconsLibrary icon="pen"></IconsLibrary>
                                </Button>
                            </ItemActions>
                        </div>
                    )}
                </div>
            </ItemWrapper>
            {isRemoved && (
                <RelatedProducts
                    productId={item.productId}
                    recomendations={item.recomendations}
                />
            )}
        </Item>
    );
}
