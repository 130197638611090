import React, { useState } from "react";

import {
    Container,
    Section,
    LevelLeft,
    LevelRight,
    LevelItem,
    BlankState,
} from "../sdk/Layout";
import { Menu, Tabs, Dropdown, Modal, Message } from "../sdk/Elements";
import { Button, Buttons } from "../sdk/Button";
import { Field, Control, SelectBox } from "../sdk/Form";
import IconsLibrary from "../components/Icons2";
import { Wrapper, Main, Topbar, SelectWrapper, FieldExtended } from "./styles";
import CartHeader from "./components/CartHeader";
import NotAllowedProducts from "./components/NotAllowedProducts";
import CartProducts from "./components/CartProducts";
import LoadingProducts from "./components/LoadingProducts";
import TableViewVirtualized from "./components/TableViewVirtualized";
import CartSidebar from "./components/CartSidebar";
import Blueberry from "../../blueberry";
import { useTranslation } from "../../lang";
import useConfirm from "../hooks/useConfirm";
import globalFilters from "./filters";
import RestrictedProducts from "./components/RestrictedProducts";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const strings = {
    related: "Relacionados",
    save: "Guardar",
    cancel: "Cancelar",
    price: "Precio",
    quantity: "Cantidad",
    comments: "Comentarios",
    addComments: "Agregar comentario",
    total: "Total",
    editComments: "Editar Comentarios",
};

const productSortAndSearch = (carts, sort, qry) => {
    const cartsFinal = [];
    carts.map((index) => {
        let cartItems = [...index.items];
        if (qry !== "") {
            cartItems = cartItems.filter((index) => {
                const title = index.title.toLowerCase();
                const slug = index.slug.toLowerCase();
                return title.indexOf(qry) > -1 || slug.indexOf(qry) > -1;
            });
        }

        if (sort !== "" && sort !== "added") {
            cartItems = cartItems.sort((a, b) => {
                if (sort === "slug" && a.slug < b.slug) return -1;
                if (sort === "slug" && a.slug > b.slug) return 1;

                if (sort === "title" && a.title < b.title) return -1;
                if (sort === "title" && a.title > b.title) return 1;

                return 0;
            });
        }

        if (cartItems.length > 0)
            cartsFinal.push({ ...index, items: cartItems });
    });

    return cartsFinal;
};

const cartsMap = (carts) => {
    return carts.reduce((prev, next) => {
        if (next.total > 0 || next.itemsCount > 0) {
            return [...prev, next._id];
        }
        return prev;
    }, []);
};

function CartContainer({
    stats,
    isLoggedIn,
    cart,
    removeItem,
    onItemEdit,
    addToCart,
    gotoRegister,
    user,
    history,
}) {
    const [limit, setLimit] = React.useState(50);
    const [_t, lang] = useTranslation();
    const [query, setQuery] = React.useState("");
    const [sort, setSort] = React.useState("slug");
    const firstTimeRef = React.useRef(false);

    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingCart, setIsLoadingCart] = React.useState(false);
    const [isLodingProducts, setIsLoadingProducts] = React.useState(false);
    const [isTableActive, setTableActive] = React.useState(() => {
        if (typeof window !== "undefined" && isLoggedIn)
            return window.localStorage.getItem("cartView") === "table";
        return false;
    });
    const [items, setItems] = React.useState(
        cart && cart.items ? cart.items : []
    );
    const [currentCarts, setCurrentCarts] = React.useState(cartsMap(cart));

    const [itemsCarts, setItemsCarts] = React.useState(
        productSortAndSearch(cart, sort, query)
    );

    const [filteredItems, setFilteredItems] = React.useState(null);
    const [itemsProducts, setItemsProducts] = React.useState(null);
    const [itemsFilters, setItemsFilters] = React.useState(null);
    const [itemCurrentFilter, setItemCurrentFilter] = React.useState(null);
    const [isEmtyingRemovedProducts, setIsEmtyingRemovedProducts] =
        React.useState(false);
    const [isEmptyingCart, setIsEmptyingCart] = React.useState(false);
    const [isGeneratingProforma, setIsGeneratingisGeneratingProforma] =
        React.useState(false);

    const [handleConfirmUpdatedCart, ConfirmModalUpdatedCart] = useConfirm({
        title: _t("updateCartHappened"),
        paragraph: "",
        btnConfirm: _t("yes"),
        btnCancel: _t("continue"),
    });
    const [handleConfirm, ConfirmModal] = useConfirm({
        title: _t("confirmEmptyRemoved"),
        paragraph: "",
        btnConfirm: _t("delete"),
        btnCancel: _t("cancel"),
    });

    const [handleConfirmRemoveCart, ConfirmRemoveModal] = useConfirm({
        title: _t("confirmEmptyCart"),
        paragraph: "",
        btnConfirm: _t("delete"),
        btnCancel: _t("cancel"),
    });

    const langJson = getLang(lang);

    const keys = Object.keys(strings);
    for (var i = keys.length - 1; i >= 0; i--) {
        strings[keys[i]] = _t(keys[i]);
    }

    const handleCartRefresh = () => {
        setIsLoading(true);
        setItemCurrentFilter("all");
        setQuery("");
        if (isTableActive) getProductsForCart();
        Blueberry.getCart()
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
    const handleHideBrandCart = (e) => {
        const { value } = e.target;
        if (currentCarts.indexOf(value) > -1) {
            setCurrentCarts((prev) => prev.filter((index) => index !== value));
        } else {
            setCurrentCarts((prev) => [...prev, value]);
        }
    };

    const handleProductSearch = (e) => {
        const { value } = e.target;
        let valueFinal = value.toLowerCase();
        let cartItems;
        setLimit(50);
        if (itemCurrentFilter == null || itemCurrentFilter === "all") {
            cartItems = productSortAndSearch(cart, sort, valueFinal);
        } else {
            cartItems = productSortAndSearch(filteredItems, sort, valueFinal);
        }
        setItemsCarts(cartItems);
        setQuery(value);
        if (analytics) analytics.track("Cart: Search", { value });
    };

    const handleProductSort = (e) => {
        const sort = e.target.value;

        let cartItems;
        setLimit(50);
        if (itemCurrentFilter == null || itemCurrentFilter === "all") {
            cartItems = productSortAndSearch(cart, sort, query);
        } else {
            cartItems = productSortAndSearch(filteredItems, sort, query);
        }
        setSort(sort);
        setItemsCarts(cartItems);
        if (analytics) analytics.track("Cart: Sort", { sort });
    };

    const handleEmptyRemovedProducts = () => {
        setIsEmtyingRemovedProducts(true);
        handleConfirm({
            callback: function (confirmed) {
                if (confirmed) {
                    Blueberry.emptyNotAvailableProducts({
                        cartIds: currentCarts,
                    })
                        .then(() => {
                            setIsEmtyingRemovedProducts(false);
                        })
                        .catch(() => {
                            setIsEmtyingRemovedProducts(false);
                        });
                } else {
                    setIsEmtyingRemovedProducts(false);
                }
            },
        });
    };

    const handleEmptyCart = () => {
        handleConfirmRemoveCart({
            callback: function (confirmed) {
                if (confirmed) {
                    setIsEmptyingCart(true);
                    Blueberry.emptyCart({ cartIds: currentCarts })
                        .then(() => {
                            setIsEmptyingCart(false);
                        })
                        .catch(() => {
                            setIsEmptyingCart(false);
                        });
                } else {
                    setIsEmptyingCart(false);
                }
            },
        });
    };

    const handleProformaGenerate = React.useCallback(
        (e) => {
            if (analytics) analytics.track("Cart Proforma");
            const sort = e.currentTarget.getAttribute("data-sort");
            setIsGeneratingisGeneratingProforma(true);
            const windowReference = window.open("", "_blank");
            Blueberry.generateProforma({ cartIds: currentCarts })
                .then((resp) => {
                    if (sort !== "pdf") {
                        windowReference.location = `${resp}${
                            sort ? "&sort=" + sort : ""
                        }`;
                    } else {
                        windowReference.location = `${resp}&type=pdf`;
                    }
                    // window.open(`${resp}${sort ? "&sort=" + sort : ""}`, "_blank");
                    setIsGeneratingisGeneratingProforma(false);
                })
                .catch((err) => {
                    setIsGeneratingisGeneratingProforma(false);
                });
        },
        [currentCarts]
    );

    const handleSetComments = React.useCallback((comments, productId) => {
        return new Promise((resolve, reject) => {
            Blueberry.addCommentsProductCart({ productId, comments })
                .then((resp) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }, []);

    const getProductsForCart = () => {
        Blueberry.cartProducts()
            .then((resp) => {
                setItemsProducts(resp);
            })
            .catch((err) => {});
    };

    const handleTableView = () => {
        if (!isLoggedIn) return null;
        if (analytics) analytics.track("Cart: Table View");
        setTableActive(true);
        window.localStorage.setItem("cartView", "table");
    };
    const handleNormalView = () => {
        setTableActive(false);
        if (analytics) analytics.track("Cart: Normal View");
        window.localStorage.setItem("cartView", "");
    };

    const handleRouteChange = () => {
        history.push(`/${lang}/checkout/?cartIds=${currentCarts.join(",")}`);
    };

    const handleCheckout = () => {
        // Cart items validation to continue with the checkout
        const cartItemsLength = cart.reduce(
            (prev, curr) => prev + (currentCarts.includes(curr._id) ? curr.itemsCount : 0), 
            0
        );
        if (!cartItemsLength) {
            alert(_t("noProductsInCart"));
            return;
        }

        const reduceF = (prev, next) =>
            prev + (next.removedItems ? next.removedItems.length : 0);
        const removedItemsLength = cart.reduce(reduceF, 0);

        setIsLoadingCart(true);
        if (isLoggedIn) {
            Blueberry.getCartAndValidate()
                .then((resp) => {
                    setIsLoadingCart(false);
                    if (
                        resp &&
                        resp.carts.reduce(reduceF, 0) !== removedItemsLength
                    ) {
                        handleConfirmUpdatedCart({
                            callback: function (confirmed) {
                                if (confirmed) {
                                    // do nothing
                                } else {
                                    handleRouteChange();
                                }
                            },
                        });
                    } else {
                        handleRouteChange();
                    }
                })
                .catch((err) => {
                    handleRouteChange();
                    setIsLoadingCart(false);
                });
        } else {
            setIsLoadingCart(false);
            const cookie_token = localStorage.getItem("ntx-cookie_token");
            if (analytics) {
                analytics.track("Lead With Cart", {
                    cookie_token,
                });
            }
            gotoRegister(cookie_token);
        }
    };

    React.useEffect(() => {
        // if there is a current tab selected dont set items from use effect
        if (itemCurrentFilter == null || itemCurrentFilter === "all") {
            // const cartItems = productSortAndSearch(
            //     cart && cart.items ? cart.items : [],
            //     sort,
            //     query
            // );

            setItemsCarts(productSortAndSearch(cart, sort, query));

            if (!firstTimeRef.current && currentCarts.length !== cart.length) {
                setCurrentCarts(cartsMap(cart));
                firstTimeRef.current = true;
            }
        }
        // check restrictions when there is change
    }, [cart]);

    // React.useEffect(() => {
    //     setTimeout(() => {
    //         Blueberry.getCartRestrictions()
    //             .then((resp) => {
    //                 setItemsRestrictions(resp);
    //             })
    //             .catch(() => {});
    //     }, 300);
    // }, [user]);

    // React.useEffect(() => {
    //     setTimeout(() => {
    //         getCartGroups();
    //     }, 200);
    // }, [cart && cart.items ? cart.items.length : 0]);

    React.useEffect(() => {
        if (isTableActive) getProductsForCart();
    }, [isTableActive]);

    if (cart == null || cart.length === 0) {
        return (
            <>
                <CartHeader
                    _t={_t}
                    isLoading={isLoading}
                    handleProformaGenerate={handleProformaGenerate}
                    isGeneratingProforma={isGeneratingProforma}
                    handleCartRefresh={handleCartRefresh}
                    handleEmptyCart={handleEmptyCart}
                    isEmptyingCart={isEmptyingCart}
                    isLoggedIn={isLoggedIn}
                    cartTitle={cart && cart.title ? cart.title : null}
                />
                <Wrapper>
                    <Main>
                        <Container>
                            <Section>
                                <BlankState>
                                    <div>
                                        <IconsLibrary icon="shopping-cart"></IconsLibrary>
                                        <h3>{_t("emptyCart")}...</h3>
                                        <p>
                                            {_t("noProductsInCart")}{" "}
                                            <a onClick={handleCartRefresh}>
                                                {_t("refresh")}
                                            </a>
                                        </p>
                                    </div>
                                </BlankState>
                            </Section>
                        </Container>
                    </Main>
                </Wrapper>
            </>
        );
    }

    return (
        <>
            <CartHeader
                _t={_t}
                isLoading={isLoading}
                itemsFilters={itemsFilters}
                itemCurrentFilter={itemCurrentFilter}
                handleProformaGenerate={handleProformaGenerate}
                isGeneratingProforma={isGeneratingProforma}
                handleCartRefresh={handleCartRefresh}
                handleEmptyCart={handleEmptyCart}
                isEmptyingCart={isEmptyingCart}
                isLoggedIn={isLoggedIn}
                cartTitle={cart && cart.title ? cart.title : null}
            />
            <Wrapper>
                <Main>
                    <Container>
                        <Section>
                            <Topbar isMobile>
                                <LevelLeft>
                                    <LevelItem>
                                        <FieldExtended hasAddons>
                                            <Control>
                                                <Button static>
                                                    <IconsLibrary icon="search"></IconsLibrary>
                                                </Button>
                                            </Control>
                                            <Control isExpanded>
                                                <input
                                                    type="text"
                                                    value={query}
                                                    onChange={
                                                        handleProductSearch
                                                    }
                                                    placeholder={_t(
                                                        "searchItemsInCart"
                                                    )}
                                                />
                                            </Control>
                                        </FieldExtended>
                                    </LevelItem>
                                </LevelLeft>
                                <LevelRight>
                                    <SelectWrapper>
                                        <Control isExpanded>
                                            <SelectBox isFullWidth>
                                                <select
                                                    value={sort}
                                                    onChange={handleProductSort}
                                                >
                                                    <option disabled value="">
                                                        {_t("orderBy")}
                                                    </option>
                                                    <option value="added">
                                                        {_t("added")}
                                                    </option>
                                                    <option value="slug">
                                                        {_t("sku")}
                                                    </option>
                                                    <option value="title">
                                                        {_t("title")}
                                                    </option>
                                                </select>
                                            </SelectBox>
                                        </Control>
                                    </SelectWrapper>
                                    <LevelItem>
                                        <Field hasAddons>
                                            <Control>
                                                <Button
                                                    onClick={handleNormalView}
                                                    className={
                                                        !isTableActive
                                                            ? "is-active"
                                                            : ""
                                                    }
                                                >
                                                    <IconsLibrary icon="list"></IconsLibrary>
                                                </Button>
                                            </Control>
                                            <Control>
                                                <Button
                                                    static={!isLoggedIn}
                                                    onClick={handleTableView}
                                                    className={
                                                        isTableActive
                                                            ? "is-active"
                                                            : ""
                                                    }
                                                >
                                                    <IconsLibrary icon="table"></IconsLibrary>
                                                </Button>
                                            </Control>
                                        </Field>
                                    </LevelItem>
                                </LevelRight>
                            </Topbar>
                            {isLodingProducts ? (
                                <LoadingProducts />
                            ) : (
                                <>
                                    <NotAllowedProducts
                                        strings={strings}
                                        _t={_t}
                                        onItemEdit={onItemEdit}
                                        handleEmptyRemovedProducts={
                                            handleEmptyRemovedProducts
                                        }
                                        isEmtyingRemovedProducts={
                                            isEmtyingRemovedProducts
                                        }
                                        cart={cart}
                                        currentCarts={currentCarts}
                                    />

                                    <CartProducts
                                        _t={_t}
                                        currentCarts={currentCarts}
                                        isTableActive={isTableActive}
                                        strings={strings}
                                        removeItem={removeItem}
                                        onItemEdit={onItemEdit}
                                        handleSetComments={handleSetComments}
                                        items={itemsCarts}
                                        limit={limit}
                                        setLimit={setLimit}
                                    />

                                    <TableViewVirtualized
                                        _t={_t}
                                        user={user}
                                        itemsProducts={itemsProducts}
                                        isTableActive={isTableActive}
                                        strings={strings}
                                        removeItem={removeItem}
                                        addToCart={addToCart}
                                        handleSetComments={handleSetComments}
                                        itemsCarts={itemsCarts}
                                        currentCarts={currentCarts}
                                    />
                                </>
                            )}
                        </Section>
                    </Container>
                </Main>
                <CartSidebar
                    handleCheckout={handleCheckout}
                    stats={stats}
                    cart={cart}
                    _t={_t}
                    handleHideBrandCart={handleHideBrandCart}
                    user={user}
                    langJson={langJson}
                    isLoadingCart={isLoadingCart}
                    currentCarts={currentCarts}
                    isLoggedIn={isLoggedIn}
                />
            </Wrapper>
            <ConfirmModal />
            <ConfirmRemoveModal />
            <ConfirmModalUpdatedCart />
        </>
    );
}

export default CartContainer;
