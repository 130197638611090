import styled from "styled-components";
import {
    Columns,
    Column,
    Container,
    Title,
    Level,
    LevelLeft,
    LevelItem,
    Box,
} from "../sdk/Layout";
import { Tabs, Dropdown, Modal, Message, Preloader } from "../sdk/Elements";
import { Button, Buttons } from "../sdk/Button";
import { Field } from "../sdk/Form";

export const Wrapper = styled(Columns)`
    /*height: calc(100% - 104px);*/
    margin: 0 !important;
    a {
        cursor: pointer;
    }
    @media (max-width: 980px) {
        display: flex;
        height: auto;
        flex-direction: column-reverse;
    }
`;

export const Main = styled(Column)`
    padding: 0;
    overflow-y: auto;
    @media (max-width: 980px) {
        width: 100%;

        overflow-y: inherit;
    }
    @media (min-width: 981px) {
        /*max-height: calc(100vh - 106px);*/
    }
`;

export const FieldExtended = styled(Field)`
    @media (min-width: 400px) {
        input {
            min-width: 250px;
        }
    }
`;

export const Topbar = styled(Level)`
    @media (max-width: 768px) {
        display: block;
        & ${Field} {
            width: 100%;
        }
        & ${LevelLeft} {
            margin-bottom: 10px;
        }
        & ${LevelItem} {
            flex: none;
        }
    }
`;

export const SelectWrapper = styled(LevelItem)`
    @media (max-width: 768px) {
        width: 100%;
        flex: auto !important;
    }
`;

export const Sidebar = styled(Column)`
    /* height: 100%; */
    padding: 0;
    background-color: ${(props) => props.theme.white};
    border-left: 1px solid ${(props) => props.theme.borderColor};
    width: 350px;
    overflow-y: auto;
    @media (max-width: 980px) {
        width: 100%;
        height: auto;
        background-color: inherit;
        padding: 10px;
    }
`;

export const CartContainer = styled.div`
    padding: 20px;
    color: ${(props) => props.theme.prmaryDark};
    @media (max-width: 980px) {
        padding: 10px;
        background-color: ${(props) => props.theme.white};
        border-radius: 6px;
        box-shadow: 0 6px 9px -10px rgba(0, 0, 0, 0.3);
    }
`;

export const Cart = styled.div`
    & > ${Title} {
        padding-top: 30px;
        text-align: center;
        color: ${(props) => props.theme.primaryDarkMode};
    }
    table {
        width: 100%;
        font-size: 14px;
        border-collapse: collapse;
        color: ${(props) => props.theme.primaryDark};
        th {
            font-weight: bold;
            text-align: left;
        }
        tr,
        th {
            padding: 20px 0;
            vertical-align: middle;
        }
        tr {
            border-top: solid 1px ${(props) => props.theme.borderColor};
            border-bottom: solid 1px ${(props) => props.theme.borderColor};
        }
        td {
            vertical-align: middle;
        }
    }
    @media (max-width: 980px) {
        table {
            tr,
            th {
                padding: 10px 0;
            }
        }
    }
    @media (max-width: 768px) {
        & > ${Title} {
            padding-top: 10px;
        }
    }
`;

export const Cubicaje = styled.div`
    text-align: center;
    margin: 15px 0;
    ${Title} {
        margin-bottom: 10px;
        svg {
            width: 14px;
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
            cursor: pointer;
            opacity: 0.7;
            color: ${(props) => props.theme.gray};
            &:hover {
                opacity: 1;
            }
        }
    }
    p {
        font-size: 16px;
        margin: 0;
        color: ${(props) => props.theme.primaryDark};
    }
    .container {
        position: relative;
        margin-top: 20px;
        display: inline-block;
        svg {
            width: 100%;
        }
        div {
            width: 75%;
            position: absolute;
            bottom: 40px;
            right: 0;
            border: solid 1px ${(props) => props.theme.primaryDarkMode};
            &:before {
                content: "";
                display: block;
                padding-top: 38%;
            }
            > span {
                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 100%;
                    width: 1px;
                    height: 10px;
                    background-color: ${(props) => props.theme.primaryDarkMode};
                }
                font-weight: bold;
                font-size: 17px;
                position: absolute;
                top: 0;
                left: 0;
                width: ${(props) => props.porcentajeOcupado}%;
                height: 100%;
                background-color: ${(props) => props.theme.primaryDarkMode};
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                span {
                    mix-blend-mode: difference;
                }
            }
        }
    }
    .description {
        text-align: left;
        padding-top: 10px;
        color: ${(props) => props.theme.primaryDark};
        p,
        h4 {
            font-size: 13px;
        }
        h4 {
            margin-top: 20px;
            margin-bottom: 5px;
        }
        p + p {
            margin-top: 5px;
            line-height: 15px;
        }
    }
`;

export const Checkout = styled(Button)`
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

export const Header = styled.div`
    background-color: ${(props) => props.theme.white};
    // padding: 20px 10px 0 10px;
    border-bottom: solid 1px ${(props) => props.theme.borderColor};
    ${Title} {
        margin-bottom: 0;
    }
    ${Tabs} {
        margin-top: 14px;
        margin-bottom: -1px;
    }
    ${Button} {
        margin-bottom: 0 !important;
    }
    ${Dropdown} {
        margin-right: 10px;
    }
    ${Container} {
        padding-top: 20px !important;
    }
    @media (max-width: 768px) {
        height: auto;
        text-align: center;
        & ${Buttons} {
            justify-content: center;
        }
        & ${Button} {
            font-size: 12px;
        }
        & ${Tabs} {
            li {
                width: 50%;
            }
        }
    }
    .nav-container {
        display: none;
    }
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        .nav-container {
            display: flex;
            position: fixed;
            width: 100%;
            z-index: 301;
        }
        margin-bottom: 0px;
    }
`;

export const ModalInfoCubicaje = styled(Modal)`
    z-index: 523;
    img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
    }
`;

export const Items = styled.div`
    margin-top: 0px;
    .items-wrapper {
        margin-top: 20px;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            overflow-y: auto;
        }
    }
`;

export const ItemWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
`;

export const Item = styled(Box)`
    padding: 0 10px;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    .image {
        padding: 10px 0;
        border-right: solid 1px ${(props) => props.theme.borderColor};
        padding-right: 10px;
        margin-right: 10px;
        @media (max-width: 580px) {
            display: flex;
            align-items: center;
            flex: none;
        }
    }
    .meta {
        color: ${(props) => props.theme.primaryDark};
        margin: 10px 0;
        @media (min-width: 581px) {
            justify-content: center;
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: 10px;
            margin-left: 10px;
            border-left: solid 1px ${(props) => props.theme.borderColor};
        }
        table {
            border-collapse: collapse;
            font-size: 14px;
            white-space: nowrap;
            tr {
                line-height: 20px;
                th {
                    text-align: left;
                    padding-right: 5px;
                    text-transform: capitalize;
                }
                td {
                    text-align: left;
                }
            }
        }
        @media (max-width: 580px) {
            table {
                tr {
                    line-height: 16px;
                }
            }
        }
    }
    .info {
        width: 100%;
        display: flex;
        align-items: center;
        @media (max-width: 580px) {
            padding: 10px 0;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }
    .actions {
        @media (min-width: 581px) {
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            margin-left: 10px;
            border-left: solid 1px ${(props) => props.theme.borderColor};
        }
    }
    @media (max-width: 768px) {
        box-shadow: 0 6px 9px -10px rgba(0, 0, 0, 0.3);
        .meta {
            table {
                font-size: 12px;
            }
        }
    }
`;

export const ItemImg = styled.div`
    text-align: center;
    flex: none;
    figure {
        width: 50px;
        height: 50px;
        margin: 5px auto;
        cursor: pointer;
    }
    ${Preloader} {
        margin-top: 5px;
    }
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
    span {
        font-size: 11px;
    }
    a {
        padding-bottom: 2px;
        color: rgb(51, 51, 51);
        border-bottom: dashed 1px ${(props) => props.theme.gray};
        font-size: 12px;
        color: ${(props) => props.theme.gray};
        :hover {
            border-bottom-style: solid;
        }
    }

    @media (max-width: 768px) {
        a {
            font-size: 11px;
        }
    }
    @media (max-width: 580px) {
        figure {
            width: 100px;
            height: 100px;
        }
    }
`;

export const ItemInfo = styled.div`
    font-size: 16px;
    width: 100%;
    h3 {
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        margin: 0;
        padding: 0;
        padding-bottom: 5px;
    }
    p {
        margin: 0;
        padding: 0;
        color: ${(props) => props.theme.gray};
        font-size: 12px;
    }
    a {
        font-size: 12px;
        color: ${(props) => props.theme.primaryDark};
        border-bottom: solid 1px ${(props) => props.theme.borderColor};
        :hover {
            border-bottom-color: ${(props) => props.theme.primaryDark};
        }
    }
    @media (max-width: 768px) {
        h3 {
            font-size: 12px;
        }
    }
`;

export const ItemActions = styled.div`
    & ${Button} + ${Button} {
        margin-top: 10px;
    }
    @media (max-width: 580px) {
        display: flex;
        & ${Button} + ${Button} {
            margin-top: 0;
            margin-left: 10px;
        }
    }
`;

export const Comment = styled.div`
    width: 100%;
    ${Button} {
        margin-bottom: 0 !important;
    }

    @media (max-width: 768px) {
        padding-right: 0;
        & ${Button} {
            font-size: 12px;
        }
    }
`;

export const ItemsTableView = styled(Box)`
    margin: 20px 0;
    > div {
        overflow-x: auto;
        overflow-y: hidden;
    }
    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        tr {
            th,
            td {
                font-size: 12px;
                padding: 5px;
                border: solid 1px ${(props) => props.theme.borderColor};
            }
            th {
                font-weight: bold;
                /*font-size: 14px;*/
                white-space: nowrap;
            }
        }
        figure {
            max-width: 100px;
            max-height: 100px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        textarea {
            font-size: 12px;
            line-height: 1.2;
        }
    }
`;
export const ItemsTableView2 = styled(Box)`
    height: calc(100vh - 154px);
    margin: 20px 0;
    width: 100%;
    overflow: auto;
    /* .ReactVirtualized__Table__headerRow {
        display: flex;
        justify-content: space-around;
    }
    .ReactVirtualized__Table__row {
        display: flex;
        justify-content: space-around;
    } */
    /*** Overrides for react-virtualized styles ***/
    .ReactVirtualized__Table__Grid {
        background-color: ${(props) => props.theme.white};
        /* border-bottom: 1px solid #e3e3e3; */
        border-right: 1px solid ${(props) => props.theme.borderColor};
    }
    .ReactVirtualized__Table__headerColumn {
        align-items: center;
        /* border: solid 1px #e3e3e3; */
        display: flex;
        flex: 1;
        height: 100%;
        min-width: 0;
        span {
            font-size: 12px;
            padding: 0px;
            width: 100%;
            text-transform: capitalize;
            text-align: center;
            font-weight: bold;
            /*font-size: 14px;*/
            white-space: nowrap;
        }

        &:last-child {
            border-right: none;
        }
    }
    .ReactVirtualized__Table__headerRow {
        border: 1px solid ${(props) => props.theme.borderColor};
        padding-right: 0 !important;
    }
    .ReactVirtualized__Table__row {
        border-bottom: 1px solid ${(props) => props.theme.borderColor};
        border-left: 1px solid ${(props) => props.theme.borderColor};
        > div {
            font-size: 12px;
            width: 100%;
            justify-content: center;
            text-align: center;
            select {
                width: auto;
            }
        }

        figure {
            max-width: 100px;
            max-height: 100px;
            cursor: zoom-in;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        textarea {
            font-size: 12px;
            line-height: 1.2;
        }
    }
    .ReactVirtualized__Table__rowColumn {
        align-items: center;
        border-right: 1px solid ${(props) => props.theme.borderColor};
        display: flex;
        flex: 1;
        height: 100%;
        min-width: 0;
        white-space: pre-wrap;
        text-align: center;

        &:last-child {
            border-right: none;
        }
    }
    .ReactVirtualized__Table__headerColumn,
    .ReactVirtualized__Table__rowColumn {
        margin-right: 3px;
    }
`;

export const NotAllowed = styled(Items)`
    margin-top: 20px;
    border: dashed 2px #da6d6d;
    padding: 20px;
    border-radius: 6px;
    ${Title} {
        margin-bottom: 0;
        color: #da6d6d;
    }
    ${Item} {
        opacity: 0.8;
        box-shadow: none;
    }
    ${Level} {
        margin-bottom: 10px;
    }
    ${Button} {
        color: #da6d6d;
    }
    @media (max-width: 768px) {
        padding: 10px;
        & ${Title}, & ${Button} {
            font-size: 12px;
        }
    }
    @media (max-width: 550px) {
        & ${ItemImg} {
            figure {
                width: 50px;
                height: 50px;
            }
        }
    }
`;

export const Restricted = styled(Items)`
    border-left: solid 2px #e6cd6f;
    .items-wrapper {
        padding-left: 10px;
    }
`;

export const Checkbrand = styled.div`
    position: relative;
    margin-bottom: 10px;
    label {
        border: solid 1px ${(props) => props.theme.borderColor};
        padding: 10px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;
        input {
            display: none;
            &:checked + .checksymbol {
                background-color: ${(props) => props.theme.primary};
            }
        }
        .checksymbol {
            display: flex;
            flex: none;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            background-color: #fff;
            border-radius: 3px;
            border: solid 2px ${(props) => props.theme.primary};

            &:before {
                display: block;
                content: "";
                border: solid 2px #fff;
                border-top: 0;
                border-left: 0;
                width: 5px;
                height: 10px;
                transform: rotate(45deg);
                margin-top: -3px;
            }
        }
        p {
            margin: 0;
            padding: 0;
            padding-left: 8px;
            font-size: 14px;
            line-height: initial;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            padding-right: 5px;
        }
    }
    .total-brand {
        flex: none;
        font-size: 14px;
    }
`;
