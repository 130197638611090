import React from "react";
import styled from "styled-components";
import { Profile } from "../../sdk/Elements";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const ProfileExtended = styled(Profile)`
    figure {
        width: 60px;
        height: 60px;
    }
    h4 {
        text-align: left;
    }

    @media (max-width: 768px) {
        figure {
            width: 40px;
            height: 40px;
            margin-right: 5px;
        }
        h4 {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 170px;
        }
    }
`;

const Counter = styled.div`
    flex: none;
    font-size: 14px;
    color: ${(props) => props.theme.gray};

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

function BrandHeader({ brandName, brandLogo, count, _t }) {
    return (
        <Wrapper>
            <ProfileExtended>
                <figure>
                    <img src={brandLogo} alt={brandName} />
                </figure>
                <h4>{brandName}</h4>
            </ProfileExtended>
            <Counter>
                {count} {_t("products")}
            </Counter>
        </Wrapper>
    );
}

export default BrandHeader;
