import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
    Container,
    Section,
    Title,
    Level,
    LevelLeft,
    LevelRight,
} from "../../sdk/Layout";
import { Tabs, Dropdown } from "../../sdk/Elements";
import { Button, Buttons } from "../../sdk/Button";
import IconsLibrary from "../../components/Icons2";
import { Header } from "../styles";
import useClickOutside from "../../hooks/useClickOutside";
import Blueberry from "../../../blueberry";

const DropdownExtended = styled(Dropdown)`
    ul,
    li {
        text-align: left;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            cursor: pointer;
        }
    }
`;

const ButtonExtended = styled(Button)`
    color: ${(props) =>
        !props.loading ? props.theme.black : "transparent"} !important;
`;

const TabsExtended = styled(Tabs)`
    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        margin-top: 40px !important;
    }
`;

const TitleExtended = styled(Title)`
    text-align-last: left;
`;

const CartTitle = styled.label`
    position: relative;
    &:hover {
        svg {
            opacity: 0.5;
        }
    }

    input {
        font-weight: 600;
        font-size: 24px;
        font-family: tahoma, verdana, sans-serif;
        /* width: 250px; */
        padding: 5px;
        margin-bottom: -5px;
        margin-top: -5px;
        background-color: transparent;
        border: none;
        color: ${(props) => props.theme.primaryDark};
        &:not(:focus) {
            position: absolute;
            opacity: 0;
        }
        &:focus + span {
            display: none;
        }
    }

    span {
        padding: 5px;
        padding-right: 25px;
        border-radius: 5px;
        position: relative;
        font-weight: 600;
        font-weight: 600;
        font-size: 24px;
        font-family: tahoma, verdana, sans-serif;
        font-size: 24px;
        color: ${(props) => props.theme.primaryDark};
        svg {
            width: 15px;
            height: 15px;
            color: ${(props) => props.theme.gray};
            position: absolute;
            top: 13px;
            right: 5px;
            opacity: 0.3;
            transition: all 100ms linear;
        }
    }
`;

const DropdownComponent = ({
    handleProformaGenerate,
    isGeneratingProforma,
    isLoggedIn,
    _t,
    white,
}) => {
    const [menu, setMenu] = React.useState(false);

    const dropdownRef = React.useRef();

    useClickOutside(dropdownRef, () => setMenu(false));

    const toggleMenu = () => {
        setMenu(!menu);
    };

    return (
        <DropdownExtended ref={dropdownRef} isActive={menu}>
            <Button
                loading={
                    isGeneratingProforma ? isGeneratingProforma : undefined
                }
                small
                white={white}
                static={!isLoggedIn}
                onClick={toggleMenu}
            >
                <IconsLibrary icon="excel-blank" />
                <span className="hide-on-standalone">{_t("export")}</span>
            </Button>
            <div>
                <div>
                    <li> {_t("excelBy")}</li>
                    <ul>
                        <li className="divisor"></li>
                        <li>
                            <a onClick={handleProformaGenerate} data-sort="sku">
                                {_t("sku")}
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={handleProformaGenerate}
                                data-sort="collection"
                            >
                                {_t("collection")}
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={handleProformaGenerate}
                                data-sort="price"
                            >
                                {_t("price")}
                            </a>
                        </li>
                        <li className="divisor"></li>
                        <li>
                            <a onClick={handleProformaGenerate} data-sort="pdf">
                                PDF
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </DropdownExtended>
    );
};

function CartHeader({
    isLoading,
    handleCartRefresh,
    isGeneratingProforma,
    handleProformaGenerate,
    _t,
    handleEmptyCart,
    isEmptyingCart,
    isLoggedIn,
    itemsFilters,
    itemCurrentFilter,
    handleSetItemFilters,
    cartTitle,
}) {
    const history = useHistory();
    const [titleInput, setTitleInput] = React.useState(cartTitle);
    const [didInitialize, setDidInitialize] = React.useState(false);
    const refTimeout = React.useRef();

    const handleBackButton = () => {
        history.goBack();
    };

    const handleSaveCartTitle = ({ title }) => {
        Blueberry.addTitleToCart({ title });
    };

    const handleFocus = (event) => event.target.select();

    const handleTitleChange = (e) => {
        const { value } = e.target;
        if (refTimeout.current) clearTimeout(refTimeout.current);
        refTimeout.current = setTimeout(() => {
            handleSaveCartTitle({ title: value });
        }, 400);
        setTitleInput(value);
        setDidInitialize(true);
    };

    React.useEffect(() => {
        if (!didInitialize) {
            setTitleInput(cartTitle);
            if (cartTitle != null && cartTitle != "") setDidInitialize(true);
        }
    }, [cartTitle]);

    return (
        <Header>
            <div className="nav-container ">
                <div className="is-visible-980 show-on-standalone">
                    <button
                        onClick={handleBackButton}
                        className="clear-btn navicon"
                    >
                        <IconsLibrary icon="arrow-left-light" />
                    </button>
                </div>
                <div className="is-visible-980 show-on-standalone is-fullwidth">
                    <TitleExtended size="5" as="h3">
                        {_t("cart")}
                    </TitleExtended>
                </div>
                <div className="is-visible-980 show-on-standalone">
                    <Buttons>
                        <DropdownComponent
                            _t={_t}
                            white
                            handleProformaGenerate={handleProformaGenerate}
                            isLoggedIn={isLoggedIn}
                            isGeneratingProforma={isGeneratingProforma}
                        />
                        <Button
                            small
                            white
                            loading={isLoading ? isLoading : undefined}
                            onClick={handleCartRefresh}
                        >
                            <IconsLibrary icon="sync-alt"></IconsLibrary>
                        </Button>
                        <Button
                            small
                            white
                            loading={
                                isEmptyingCart ? isEmptyingCart : undefined
                            }
                            onClick={handleEmptyCart}
                        >
                            <IconsLibrary icon="eraser"></IconsLibrary>
                        </Button>
                    </Buttons>
                </div>
            </div>
            <Container isFluid>
                <Level className="hide-on-standalone">
                    <LevelLeft>
                        <CartTitle>
                            <span>{_t("cart")}</span>
                        </CartTitle>
                    </LevelLeft>
                    <LevelRight>
                        <Buttons>
                            <DropdownComponent
                                isLoggedIn={isLoggedIn}
                                _t={_t}
                                handleProformaGenerate={handleProformaGenerate}
                                isGeneratingProforma={isGeneratingProforma}
                            />
                            <ButtonExtended
                                small
                                loading={isLoading ? isLoading : undefined}
                                onClick={handleCartRefresh}
                            >
                                <IconsLibrary icon="sync-alt"></IconsLibrary>
                                <span> {_t("refresh")}</span>
                            </ButtonExtended>
                            <Button
                                small
                                loading={
                                    isEmptyingCart ? isEmptyingCart : undefined
                                }
                                onClick={handleEmptyCart}
                            >
                                <IconsLibrary icon="eraser"></IconsLibrary>
                                <span> {_t("empty")}</span>
                            </Button>
                        </Buttons>
                    </LevelRight>
                </Level>
                <TabsExtended>
                    <ul>
                        <li
                            onClick={() => handleSetItemFilters("all")}
                            className={
                                itemCurrentFilter !== "all" &&
                                itemCurrentFilter != null
                                    ? ""
                                    : "is-active"
                            }
                        >
                            <a>{_t("all")}</a>
                        </li>
                        {itemsFilters == null || itemsFilters.length === 0
                            ? null
                            : Object.keys(itemsFilters).map((index) => (
                                  <li
                                      key={index}
                                      className={
                                          itemCurrentFilter === index
                                              ? "is-active"
                                              : ""
                                      }
                                  >
                                      <a
                                          onClick={() =>
                                              handleSetItemFilters(index)
                                          }
                                      >
                                          {index.indexOf(" ") > -1
                                              ? `${_t(
                                                    index
                                                        .split(" ")[0]
                                                        .toLowerCase()
                                                )} ${_t(
                                                    index
                                                        .split(" ")[1]
                                                        .toLowerCase()
                                                )}`
                                              : index}{" "}
                                          ({itemsFilters[index].count})
                                      </a>
                                  </li>
                              ))}
                        {/* <li>
                            <a>{_t("inventory")}</a>
                        </li> */}
                    </ul>
                </TabsExtended>
            </Container>
        </Header>
    );
}

export default CartHeader;
