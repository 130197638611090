import React from "react";
import CartItem from "./CartItem";
import { Items } from "../styles";
import BrandHeader from "./BrandHeader";

const isBottom = (el) => {
    return (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 2000
    );
};

function CartProducts({
    items,
    _t,
    handleSetComments,
    removeItem,
    onItemEdit,
    strings,
    isTableActive,
    currentCarts,
    limit,
    setLimit,
}) {
    const groups = [];
    const itemsToRender = [];

    const trackScrolling = () => {
        const wrappedElement = document.getElementById("cart-item-list");
        if (isBottom(wrappedElement)) {
            // document.removeEventListener('scroll', trackScrolling);
            setLimit((prev) => prev + 25);
        }
    };

    React.useEffect(() => {
        document.addEventListener("scroll", trackScrolling);
        return () => {
            document.removeEventListener("scroll", trackScrolling);
        };
    }, []);

    if (isTableActive) return null;
    let currentCount = 0;
    for (let index = 0; index < items.length; index++) {
        const group = [];
        const count = items[index].items.length;
        if (
            currentCount < limit &&
            currentCarts.indexOf(items[index]._id) > -1
        ) {
            const next = limit - currentCount;
            for (let j = 0; j < Math.min(count, next); j++) {
                const item = items[index].items[j];

                group.push(
                    <CartItem
                        key={item.productId}
                        cookie_token={items[index].cookie_token}
                        item={item}
                        removeItem={removeItem}
                        strings={strings}
                        handleSetComments={handleSetComments}
                        onItemEdit={onItemEdit}
                    />
                );
                currentCount++;
            }

            groups.push(
                <div className="items-wrapper" key={items[index]._id}>
                    <BrandHeader
                        brandName={
                            items[index].brand
                                ? items[index].brand.name
                                : "Brand no name"
                        }
                        brandLogo={
                            items[index].brand
                                ? items[index].brand.imageLogo
                                : ""
                        }
                        count={count}
                        _t={_t}
                    />
                    {group}
                </div>
            );
        }
    }

    return <Items id="cart-item-list">{groups}</Items>;
}

export default CartProducts;
