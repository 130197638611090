import { useState, useEffect } from "react";

function useJsonSettings() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [jsonSettings, setJsonSettings] = useState({});

    useEffect(() => {
        if (typeof window !== "undefined") {
            setJsonSettings(
                window._INITIAL_DATA_ && window._INITIAL_DATA_.length > 2
                    ? window._INITIAL_DATA_[window._INITIAL_DATA_.length - 2]
                    : {}
            );
        }
    }, [typeof window !== "undefined" ? window._INITIAL_DATA_ : []]);

    return jsonSettings;
}

export default useJsonSettings;
