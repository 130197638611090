import React from "react";
import { Preloader } from "../../sdk/Elements";
import { Items, Item, ItemImg, ItemInfo } from "../styles";

function LoadingProducts({}) {
    return (
        <Items id="cart-item-list">
            <div className="items-wrapper">
                {[0, 1, 2, 3, 4, 5, 7, 8, 9, 10].map((index) => (
                    <Item key={`prealoder${index}`}>
                        <div className="image">
                            <ItemImg>
                                <span>
                                    <Preloader />
                                </span>

                                <Preloader square />
                            </ItemImg>
                        </div>
                        <div className="info">
                            <ItemInfo>
                                <h3>
                                    {" "}
                                    <Preloader />
                                </h3>
                            </ItemInfo>
                            <div className="meta">
                                <Preloader />
                            </div>
                        </div>
                    </Item>
                ))}
            </div>
        </Items>
    );
}

export default LoadingProducts;
