import React from "react";
import styled from "styled-components";
import InternalLink from "../../components/InternalLink";
import { useTranslation } from "../../../lang";
const Wrapper = styled.div`
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    border-top: solid 1px ${(props) => props.theme.borderColor};
    h3 {
        font-size: 12px;
        margin-bottom: 10px;
    }
`;

const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -1%;
    margin-right: -1%;
    margin-top: -1%;
    margin-bottom: -1%;
`;

const Item = styled(InternalLink)`
    max-width: 19%;
    margin: 0.5%;
    display: flex;
    align-items: center;
    figure {
        flex: none;
        img {
            object-fit: contain;
            width: 40px;
            height: 40px;
        }
    }
    h4 {
        font-size: 8px;
        padding-left: 5px;
        max-width: 100px;
        color: ${(props) => props.theme.primaryDark};
    }

    @media (max-width: 768px) {
        max-width: 32.33333%;
        figure {
            img {
                width: 30px;
                height: 30px;
            }
        }
        h4 {
            font-size: 6px;
            padding-left: 3px;
        }
    }
`;

function RelatedProducts({ recomendations, productId }) {
    if (recomendations == null || recomendations.length === 0) return null;
    const [_t] = useTranslation();
    return (
        <Wrapper>
            <h3>
                {_t("similarProducts")} {_t("available")}
            </h3>

            <Items>
                {recomendations.map((index, i) => (
                    <Item
                        key={`${productId}-recom-${index._id}`}
                        to={`/products/${index.slug}`}
                    >
                        <figure>
                            <img
                                src={
                                    index.imagesUrl &&
                                    index.imagesUrl.length > 0
                                        ? index.imagesUrl[0].thumb
                                        : ""
                                }
                                alt={
                                    index.title ||
                                    index.title_pt ||
                                    index.title_eng
                                }
                            />
                        </figure>
                        <h4>
                            {index.title || index.title_pt || index.title_eng}
                        </h4>
                    </Item>
                ))}
            </Items>
        </Wrapper>
    );
}

export default RelatedProducts;
