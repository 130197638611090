import React from "react";
import styled from "styled-components";

import {
    Container,
    Section,
    Title,
    Level,
    LevelLeft,
    LevelRight,
} from "../../sdk/Layout";
import { Tabs, Dropdown } from "../../sdk/Elements";
import { Button, Buttons } from "../../sdk/Button";
import IconsLibrary from "../../components/Icons2";
import { Item, Items, ItemInfo, NotAllowed, ItemImg } from "../styles";
import CartItem from "./CartItem";

function NotAllowedProducts({
    cart,
    strings,
    onItemEdit,
    handleEmptyRemovedProducts,
    currentCarts,
    isEmtyingRemovedProducts,
    _t,
}) {
    const [isVisible, setIsivible] = React.useState(false);

    React.useEffect(() => {
        const countRemoved = cart.reduce(
            (prev, next) =>
                prev + (next.removedItems ? next.removedItems.length : 0),
            0
        );

        if (countRemoved > 0) {
            setIsivible(true);
        } else {
            setIsivible(false);
        }
    }, [cart]);
    if (!isVisible) return null;

    const items = [];

    for (let j = 0; j < cart.length; j++) {
        const currentCart = cart[j];
        if (
            currentCart.removedItems &&
            currentCarts.indexOf(currentCart._id) > -1
        ) {
            for (
                let index = 0;
                index < currentCart.removedItems.length;
                index++
            ) {
                const item = currentCart.removedItems[index];
                items.push(
                    <CartItem
                        key={item.productId}
                        item={item}
                        strings={strings}
                        isRemoved={true}
                        onItemEdit={onItemEdit}
                    />
                );
            }
        }
    }

    return (
        <NotAllowed>
            <Level isMobile>
                <LevelLeft>
                    <Title as="h4" size="6">
                        {_t("notAvailableAnymore")}
                    </Title>
                </LevelLeft>
                <LevelRight>
                    <Button
                        onClick={handleEmptyRemovedProducts}
                        loading={isEmtyingRemovedProducts}
                        small
                    >
                        {_t("removeAll")}
                    </Button>
                </LevelRight>
            </Level>
            <Items>
                <div className="items-wrapper">{items}</div>
            </Items>
        </NotAllowed>
    );
}

export default React.memo(NotAllowedProducts);
