export default {
    arancelError: "Review these tariffs with your import department:",
    completeCart:
        "To complete the cubic capacity of your container you only have to add 5 meters of our assortment",
    benefits: [
        "Deliveries on time, avoid delays of your merchandise",
        "Avoid breakages",
        "More efficient use of the freight ",
        "Flawless documentation",
        "Avoid merchandise handling charges",
    ],
    benefitsT: "Benefits",
};
