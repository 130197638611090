import React from "react";
import { Helmet } from "react-helmet";
import Blueberry from "../../blueberry";
import withTracker from "../../blueberry/connect";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
import SEOFieldsHelmet, { websiteName } from "../utils/SEOFieldsHelmet";
import CartContainer from "./CartContainer";

// const LoadableComponent = loadable(() => import('./AccountView'))

class Cart extends ProductsSectionTemplate {
    state = {
        ...this.productDefaultState,
        defaultQuantity: null,
        isLoadingCart: false,
    };

    onItemEdit = (slug, isOpenSimilar, defaultQuantity, cookie_token) => {
        const self = this;
        this.setState({ defaultQuantity });
        return new Promise((resolve, reject) => {
            Blueberry.product({ slug })
                .then((resp) => {
                    Blueberry.productView({ slug });
                    self.toggleModalProduct(
                        { ...resp, updateButton: true },
                        cookie_token
                    );

                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    refetchOnPriceChange = () => {
        if (this.state.product && this.state.product.slug) {
            this.onItemEdit(this.state.product.slug);
        }
    };

    renderBody() {
        const { lang } = this.props.match.params;

        const { cart, stats, user } = this.props.store;
        const { location } = this.props;
        const { isLoadingCart } = this.state;

        const title = "Cart - " + websiteName;
        const description =
            title + lang === "es"
                ? "es una tienda en linea para comprar mayorista de decoracion, hogar y navidad. Nuestras marcas principales de decoracion son Santini y Concepts Life. En nuestra tienda podras encontrar variedad de muebles, figuras, arboles, y mas."
                : "it is an online store to buy decoration, home and Christmas in wholesale. Our main decoration brands are Santini and Concepts Life. In our store you can find a variety of furniture, figures, trees, and more.";

        return (
            <>
                <SEOFieldsHelmet
                    seo={null}
                    lang={lang}
                    bodyClassName="hide-search-bar-standalone gray-bg"
                    defaultTitle={title}
                    defaultDescription={description}
                />
                <CartContainer
                    cart={cart}
                    stats={stats}
                    user={user}
                    lang={lang}
                    isLoggedIn={this.isLoggedIn()}
                    removeItem={this.removeItem}
                    onItemEdit={this.onItemEdit}
                    addToCart={this.addToCart}
                    gotoRegister={this.gotoRegister}
                    history={this.props.history}
                    handleCheckout={this.handleCheckout}
                    isLoadingCart={isLoadingCart}
                />
            </>
        );
    }
}

export default withTracker()(Cart);

// export default MyAccount
