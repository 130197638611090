export default {
    arancelError:
        "Revisar con su departamento de importaciones estos aranceles:",
    completeCart:
        "Para completar el cubicaje de tu contenedor solo tienes que agregar 5 metros de nuestro surtido.",
    benefits: [
        "Despachos a tiempo, evita retrasos de tu mercadería",
        "Evita roturas",
        "Uso más eficiente del flete a pagar",
        "Documentación impecable",
        "Evita cargos de manejo de la mercadería",
    ],
    benefitsT: "Lucros",
};
