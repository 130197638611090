import React from "react";
import styled from "styled-components";

import {
    Container,
    Section,
    Title,
    Level,
    LevelLeft,
    LevelRight,
    Box,
} from "../../sdk/Layout";
import { Tabs, Dropdown, Message } from "../../sdk/Elements";
import { Button, Buttons } from "../../sdk/Button";
import { Field, Control, Checkbox } from "../../sdk/Form";
import IconsLibrary from "../../components/Icons2";
import ModalPortal from "../../components/ModalPortal";
import { numberWithCommas } from "../../utils";
import renderIf from "../../utils/renderIf";
import useJsonSettings from "../../hooks/useJsonSettings";
import {
    Sidebar,
    CartContainer,
    Cubicaje,
    Checkout,
    Cart,
    ModalInfoCubicaje,
    Checkbrand,
} from "../styles";

function CartSidebar({
    stats,
    _t,
    isLoggedIn,
    handleCheckout,
    langJson,
    isLoadingCart,
    user,
    handleHideBrandCart,
    currentCarts,
    cart,
}) {
    const [modal, setModal] = React.useState(false);
    const jsonSettings = useJsonSettings();

    const toggleModal = () => {
        setModal((prev) => !prev);
    };

    const cmbTotal = cart.reduce((prev, next) => {
        return (
            prev +
            (currentCarts.indexOf(next._id) > -1 ? next.totalCubicaje : 0)
        );
    }, 0);

    const total = cart.reduce((prev, next) => {
        return prev + (currentCarts.indexOf(next._id) > -1 ? next.total : 0);
    }, 0);
    const itemsCount = cart.reduce((prev, next) => {
        return (
            prev + (currentCarts.indexOf(next._id) > -1 ? next.itemsCount : 0)
        );
    }, 0);

    const getCubicajePercentage = () => {
        const cbm = cmbTotal;
        if (cbm <= 27 && cbm > 0) {
            return Math.round((cbm / 27) * 100);
        } else if (cbm <= 57 && cbm > 27) {
            return Math.round((cbm / 57) * 100);
        } else if (cbm <= 67 && cbm > 57) {
            return Math.round((cbm / 67) * 100);
        } else if (cbm > 67) {
            return 100;
        }

        return 0;
    };

    return (
        <>
            <Sidebar isNarrow>
                <CartContainer>
                    <Cart>
                        {renderIf(
                            jsonSettings == null ||
                                (jsonSettings &&
                                    !jsonSettings.offCheckoutForSmall) ||
                                user == null ||
                                (user &&
                                    user.tags &&
                                    user.tags.indexOf("feria") <= -1) ||
                                (user &&
                                    user.clientSegment !== "small" &&
                                    jsonSettings.offCheckoutForSmall)
                        )(
                            <>
                                <div>
                                    {cart.map((index) => {
                                        if (index.total === 0) return null;
                                        return (
                                            <Checkbrand key={index._id}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        onChange={
                                                            handleHideBrandCart
                                                        }
                                                        value={index._id}
                                                        checked={
                                                            currentCarts.indexOf(
                                                                index._id
                                                            ) > -1
                                                        }
                                                    />
                                                    <span className="checksymbol"></span>
                                                    <p>
                                                        {index.title
                                                            ? index.title
                                                            : index.brand
                                                            ? index.brand.name
                                                            : "No name"}
                                                    </p>
                                                    <span className="total-brand">
                                                        {numberWithCommas(
                                                            index.total
                                                        )}
                                                    </span>
                                                </label>
                                            </Checkbrand>
                                        );
                                    })}
                                </div>
                                <Checkout
                                    onClick={handleCheckout}
                                    isFullWidth
                                    primary
                                    loading={
                                        isLoadingCart
                                            ? isLoadingCart
                                            : undefined
                                    }
                                >
                                    {isLoggedIn
                                        ? _t("confirmOrder")
                                        : _t("confirmOrderLogout")}
                                    &nbsp;({currentCarts.length} {_t("brands")})
                                </Checkout>
                            </>
                        )}

                        {renderIf(
                            user &&
                                user.clientSegment === "small" &&
                                jsonSettings.offCheckoutForSmall
                        )(
                            <Message alert>
                                {isLoggedIn
                                    ? _t("pleaseContactSalesrepToConfirm")
                                    : _t("helpTextCartLogout")}
                            </Message>
                        )}

                        <Title as="h4" size="6">
                            {_t("orderDetail")}
                        </Title>
                        <table>
                            <tbody>
                                <tr>
                                    <th> {_t("quantityOfItems")}</th>
                                    <td>{itemsCount}</td>
                                </tr>
                                {/* <tr>
                                    <th>Sub-total</th>
                                    <td>$1000</td>
                                </tr> */}

                                {/* <tr>
                                    <th>Impuestos (10%)</th>
                                    <td>{stats.cbm.toFixed(4)}</td>
                                </tr> */}
                                <tr>
                                    <th>Total</th>
                                    <td>{numberWithCommas(total)}</td>
                                </tr>
                            </tbody>
                        </table>

                        <Cubicaje porcentajeOcupado={getCubicajePercentage()}>
                            <Title isCentered as="h5" size="6">
                                {_t("cubicaje")}{" "}
                                <a onClick={toggleModal}>
                                    <IconsLibrary icon="question-circle"></IconsLibrary>
                                </a>
                            </Title>
                            <p>
                                {cmbTotal.toFixed(4)} M<sup>3</sup>
                            </p>

                            <div className="container">
                                <div>
                                    <span>
                                        <span>{getCubicajePercentage()}%</span>
                                    </span>
                                </div>
                                <IconsLibrary icon="truck-siluet"></IconsLibrary>
                            </div>

                            <div className="description">
                                <p>{langJson.completeCart}</p>
                                <h4>{langJson.benefitsT}</h4>
                                {langJson.benefits.map((index, i) => (
                                    <p key={`benefits-${i}`}>
                                        {i + 1}. {index}
                                    </p>
                                ))}
                            </div>
                        </Cubicaje>

                        <Message alert>
                            {isLoggedIn
                                ? _t("helpTextCart")
                                : _t("helpTextCartLogout")}
                        </Message>
                    </Cart>
                </CartContainer>
            </Sidebar>
            <ModalPortal>
                <ModalInfoCubicaje className={modal ? "is-active" : ""}>
                    <Button onClick={toggleModal}></Button>
                    <span onClick={toggleModal}></span>
                    <Box>
                        <img
                            src="https://www.noritex.com/wM4EERCkuoiFBSr5Qnoritex_container.jpg"
                            alt="Cuantos metros cubicos caben en un contenedor"
                        />
                    </Box>
                </ModalInfoCubicaje>
            </ModalPortal>
        </>
    );
}

export default React.memo(CartSidebar);
